import {
  List,
  Pagination,
  Edit,
  SimpleList,
  FilterForm,
  TextInput,
  DateInput,
  FilterButton, FunctionField, ImageField, TextField, BooleanInput, RadioButtonGroupInput
} from "react-admin"
import React from "react"
import Chat from '~/components/chat/Chat'
import PersonIcon from '@mui/icons-material/Person'
import moment from "moment/moment"
import Cookies from "universal-cookie"
import { TOKEN_COOKIE_KEY } from "~/constants/auth"
import { Stack } from "@mui/material"

const cookies = new Cookies()

export const Chats = () => {
  const userId = JSON.parse(atob(cookies.get(TOKEN_COOKIE_KEY).split('.')[1])).id

  return (
    <List
      pagination={<ChatsPagination />}
      emptyWhileLoading
    >
      <ListToolbar />
      <SimpleList
        leftAvatar={record => {
          if(record?.user?.properties?.avatar?.value[0]?.url){
            return (
              <img
                src={`${record?.user?.properties?.avatar?.value[0].url}`}
                height={40}
                width={40}
                alt="avatar"
                object-fit="fill"
                object-position="center"
              />
            )
          }
          return <PersonIcon />
        }}
        primaryText={record => {
          if(!(record?.user?.properties?.name?.value && record?.user?.properties?.surname?.value)) {
            return `user_${record?.user?.id}(${record?.user?.roles[0].name ?? ''})`
          } else {
            return `${record?.user?.properties?.name?.value ?? ''} ${record?.user?.properties?.surname?.value ?? ''}` + `(${record?.user?.roles[0].name ?? ''})`
          }
        }}
        secondaryText={record => {
          if(record?.lastMessage?.text && record?.lastMessage?.text.length > 60) {
            return record?.lastMessage?.text.slice(0, 60) + "..." ?? null
          }
          return record?.lastMessage?.text ?? null
        }}
        tertiaryText={record => {
          const currentDate = moment(record?.lastMessage?.updatedAt).utc()
          const diff = moment.duration(moment().diff(currentDate))
          if (diff.asHours() < 18) {
            return currentDate.format('HH:mm')
          } else if (diff.asDays() < 6) {
            return currentDate.format('dddd')
          } else {
            return currentDate.format('DD-MM-YYYY')
          }
        }}
        rightIcon={record => {
          if (!record?.lastMessage?.isRead && record?.lastMessage?.userId !== userId) {
            return <div style={{
              margin: '20px',
              width: '20px',
              height: '20px',
              backgroundColor: '#ac9f9f',
              color: '$f00',
              borderRadius: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'absolute',
              top: "0"
            }}>
              {record[`unreadMessagesCount`]}
            </div>
          }
        }}
        rowSx={record => !record?.lastMessage?.isRead && record?.lastMessage?.userId !== userId
          ? { backgroundColor: "rgba(13,125,237,0.5)" }
          : { position: "relative" }
        }
      />
    </List>
  )
}

export const ChatsEdit = () => {
  return (
    <Edit>
      <Chat />
    </Edit>
  )
}

const ChatsPagination = () => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />
)

const ListToolbar = () => {

  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={0}
      sx={{ ml: 2, mr: 2 }}
    >
      <FilterForm filters={[
        <TextInput label="Поиск" source="query" />,
        <TextInput label="ID" source="id" />,
        <DateInput label="Дата создания чата" source="createdAt" />,
        <RadioButtonGroupInput
          label={'Сообщения'}
          source="custom.existsUnreadMessages"
          choices={[
            { name: 'Не прочитанные', value: false },
          ]}
          optionText={"name"}
        />,
      ]} />
      <div>
        <FilterButton filters={[
          <TextInput label="Поиск" source="query" />,
          <TextInput label="ID" source="id" />,
          <DateInput label="Дата создания чата" source="createdAt" />,
          <RadioButtonGroupInput
            label={'Сообщения'}
            source="custom.existsUnreadMessages"
            choices={[
              { name: 'Не прочитанные', value: false },
            ]}
            optionText={"name"}
          />,
        ]}/>
      </div>
    </Stack>
  )
}