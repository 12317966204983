import { useRecordContext, useRedirect } from "react-admin"
import React, { useEffect, useMemo, useRef, useState } from "react"
import {
  AttachmentButton,
  Avatar,
  ChatContainer,
  ConversationHeader,
  Message,
  MessageInput,
  MessageList
} from "@chatscope/chat-ui-kit-react"
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css"
import _ from "lodash"
import { asyncEmit, chatSocket } from "~/modules/socket"
import { MessageCreate } from "~/api/chat-api"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { MessageType } from "~/utils/types"
import { transformListToDict } from "~/modules/entity"
import moment from "moment"
import CloseIcon from "@mui/icons-material/Close"
import { convertFileToBase64 } from "~/utils/image"

export default function Chat() {
  const record = useRecordContext()

  const fileRef = useRef(null)
  const [image, setImage] = useState<string | null>(null)
  const [file, setFile] = useState<{ name: string, mime: string, value: any } | null>(null)
  const [textValue, setTextValue] = useState<string>('')

  const chatId = record.id
  const adminId = 1

  const user = record?.members?.find((u: any) => u?.userId !== adminId)?.user
  const transformProperties = transformListToDict(user?.properties)
  const avatar = transformProperties?.avatar?.value?.[0]?.url
  const name = transformProperties?.name?.value
  const surname = transformProperties?.surname?.value
  const fullName = `${name} ${surname ? surname : ""}` ?? 'Пользователь'

  const queryClient = useQueryClient()
  const listRef = useRef()

  // message request
  const messagesQueryKey = useMemo(
    () =>
      [
        "chat-messages",
        _.merge(
          {},
          { filter: { chatId } },
          {
            order: {
              field: "createdAt",
              by: "asc"
            }
          }
        )
      ] as const,
    [chatId]
  )
  const { data: messages } = useQuery({
    queryKey: messagesQueryKey,
    queryFn: ({ queryKey }) => {
      return asyncEmit(chatSocket, "chats-messages-list", queryKey[1]).then(
        r => r.rows
      )
    },
    placeholderData: []
  })


  useEffect(() => {
    if(
      messages?.length
      && !messages?.at(-1)?.isRead
      && record?.members?.find((user: any) => user?.user.roles.find((role: any) => role.code === 1))
    ){
      readMessage()
    }
  }, [messages])

  //socket listeners
  useEffect(() => {
    const createMessageListener = message => {
      if (chatId === message.chatId) {
        queryClient.setQueryData(messagesQueryKey, old => [...old, message])
      }
    }

    chatSocket.on("chats-messages-create", createMessageListener)

    return () => {
      chatSocket.off("chats-messages-create", createMessageListener)
    }
  }, [chatId, messagesQueryKey, queryClient])

  const readMessage = () => {
    const { id, chatId } = messages.at(-1)
    const res = (async () => {
      await asyncEmit(chatSocket, "chats-messages-read", {id, chatId})
    })()
  }

  // send message handler
  const sendMessage = async (message: string) => {
    if(
      !messages?.at(-1)?.isRead
      && messages?.at(-1).user.id === user.id
    ){
      readMessage()
    }
    const text = message.replace(/(^(\s+|&nbsp;|<br>)+|(\s+|&nbsp;|<br>)+$)/g, '')
    if(text.length === 0 && !file?.mime) {
      return
    }
    const data: MessageCreate = file?.mime
      ? {
        text: text ?? null,
        media: [
          {
            name: file.name,
            value: file.value,
            mime: file.mime
          }
        ],
        chatId
      } : {
        text: text ?? null,
        chatId
      }

    // const res = await ChatApi.sendMessage(chatId, data)
    const res = await asyncEmit(chatSocket, "chats-messages-create", data)
    queryClient.setQueryData(messagesQueryKey, old => [...old, res])
    setTextValue('')
    deleteImage()

    listRef.current?.scrollToOffset?.({ offset: 0 })
  }

  const onFileChange = async (e) => {
    const selectedFile = e.target.files[0]
    if (selectedFile) {
      const imageUrl = URL.createObjectURL(selectedFile)
      const base64 = await convertFileToBase64({ rawFile: selectedFile })

      setFile({
        name: selectedFile.name,
        mime: selectedFile.type,
        value: base64
      })
      setImage(imageUrl)
      setTextValue(' ')
    }
  }

  const deleteImage = () => {
    setImage(null)
    setFile(null)
  }

  const message = m => {
    const direction =
      m.user.id === adminId ? MessageType.Outgoing : MessageType.Incoming

    return (
      <Message
        key={m.id}
        model={{
          direction,
          position: "single"
        }}
      >
        <Message.CustomContent>
          {m.media.length !== 0 && m.media.map((image) => (
            <Message.ImageContent
              key={image.id}
              src={image.url}
              alt={`${image.name}`}
              width={200}
            />
          ))}
          {m.text && <Message.TextContent text={m.text} />}
        </Message.CustomContent>
      </Message>
    )
  }

  const statusInfo = (
    <>
      {user && user?.isOnline
        ? "online"
        : `last seen ${moment(user.lastOnlineAt).format("HH:mm")}`}
    </>
  )

  const redirect = useRedirect()

  return (
    <ChatContainer style={styles}>
      <div as={ConversationHeader} >
        <ConversationHeader>
          <div as={Avatar} onClick={() => {redirect('show', `users/${user.id}`)}} style={{cursor: "pointer"}}>
            <Avatar name="avatar" src={avatar} />
          </div>
          <ConversationHeader.Content userName={fullName} info={statusInfo} />
        </ConversationHeader>
      </div>
      <MessageList ref={listRef} style={{ height: 720 }}>
        {messages?.map(message)}
      </MessageList>
      <div as={MessageInput}>
        <div>
          {image && (
            <div style={{ marginLeft: "5px", position: "relative", width: "max-content" }}>
              <div style={{ position: "absolute", top: "-20px", right: "-20px", cursor: "pointer" }}
                onClick={deleteImage}>
                <CloseIcon />
              </div>
              <img
                src={image}
                alt="preview"
                style={{ maxWidth: "100px", maxHeight: "100px" }}
              />
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            borderTop: "1px solid #d1dbe4",
            justifyContent: "center"
          }}
        >
          <AttachmentButton
            style={{
              fontSize: "1.5em",
              paddingLeft: "0em",
              paddingRight: "0em"
            }}
            onClick={() => fileRef.current.click()}
          />
          <input type="file" id="file" ref={fileRef} onChange={onFileChange} style={{ display: "none" }} />
          <MessageInput
            placeholder="Type message here"
            value={textValue}
            onChange={(e) => setTextValue(e)}
            onSend={sendMessage}
            attachButton={false}
            sendDisabled={false}
            style={{
              width: "94%",
              borderTop: "none"
            }}
          />
        </div>
      </div>
    </ChatContainer>
  )
}